// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-painting-js": () => import("./../src/templates/single-painting.js" /* webpackChunkName: "component---src-templates-single-painting-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-commissions-success-js": () => import("./../src/pages/commissions-success.js" /* webpackChunkName: "component---src-pages-commissions-success-js" */),
  "component---src-pages-commissions-js": () => import("./../src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

